export default function ShiftOne() {
  return (
    <div className='page page-five'>
      <div data-aos='zoom-in-left' className='row flex-row'>
        <div className='column flex-column'>
          <h1 className='scroll-message'>
            You might be feeling pretty envious at this point...
          </h1>
        </div>
      </div>
    </div>
  );
}
