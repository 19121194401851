export default function ShiftTwo() {
  return (
    <div className='page page-six globe-background'>
      <div className='row flex-row'>
        <div data-aos='zoom-in-right' className='column flex-column'>
          <h1 className='scroll-message'>
            ... but now let's look at you vs. the rest of the world.
          </h1>
        </div>
      </div>
    </div>
  );
}
